/* * {
  scroll-behavior: smooth;
} */

@font-face {
  font-family: "Helvetica";
  src: local("Helvetica"),
    url(./assets/fonts/Helvetica-Font/Helvetica.ttf) format("truetype");
}

@font-face {
  font-family: "HelveticaLt";
  src: local("HelveticaLt"),
    url(./assets/fonts/Helvetica-Font/helvetica-light.ttf) format("truetype");
}