.container-2 .swiper {
    width: 100%;
    height: 100%;
    margin-right: 1vw;
}

.container-2 .swiper-slide {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-2 .swiper-slide img {
    display: block;
    width: 100%;
    height: 260px;
    object-fit: contain;
    border-radius: 15px !important;
}

.container-2 .swiper-pagination-bullet {
    border: 2px solid black !important;
}

.container-2 .swiper-pagination-bullet-active {
    background-color: black !important;
}

.container-2 .swiper-pagination-bullets {
    background-color: rgba(255, 255, 255, 0.7);
    width: fit-content !important;
    padding: 1% !important;
    left: 50% !important;
    transform: translateX(-50%);
    border-radius: 30px;
}

@media screen and (max-width: 1600px) {
    .container-2 .swiper-slide img {
        display: block;
        width: 100%;
        height: 200px;
        object-fit: contain;
        border-radius: 15px !important;
    }
}

@media screen and (max-width: 800px) {
    .container-2 .swiper-slide img {
        display: block;
        width: 100%;
        height: 180px;
        object-fit: cover;
        border-radius: 15px !important;
    }
}