body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* WebKit (Chrome, Safari) */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Firefox */
::-moz-scrollbar {
  width: 8px;
}

::-moz-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}
/* 
html {
  -ms-overflow-style: none;
  scrollbar-width: none;
} */
::-moz-scrollbar-thumb:hover {
  background-color: #555;
}

.hamburger-react[aria-expanded="true"]{
  color: #002F87 !important;
}
@media screen and (max-width: 800px) {
  .css-dmmspl-MuiFormGroup-root {
    flex-direction: row !important;
    justify-content: center;
  }
  .contact .css-fsedd-MuiInputBase-root-MuiInput-root {
    width: 80% !important;
    font-size: 18px !important;
  }
  .contact .MuiInputBase-input {
    font-size: 15px !important;
    width: 100% !important;
  }
  .search .MuiInputBase-input {
    font-size: 14px !important;
    width: 100% !important;
  }
  .message .MuiInputBase-input {
    font-size: 15px !important;
    width: 100% !important;
  }
}

.custom-loader {
  width:50px;
  height:50px;
  border-radius:50%;
  border:8px solid;
  border-color:#766DF4 #0000;
  animation:s1 1s infinite;
  z-index: -1;
  position: absolute;
  margin-top: 10vh;
}
@keyframes s1 {to{transform: rotate(.5turn)}}

.captcha {
  display: flex !important;
  justify-content: center !important;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.fade-in-complex {
  animation: fadeIn 1s ease-in-out;
}