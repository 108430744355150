.container-1 {
  width: 100%;
}

.container-serve, .darkivore-page, .container-tace {
  width: 100%;
}

.container-1 .swiper-pagination-bullet {
  background-color: #fff !important;
  border: 5px solid #fff !important;
  opacity: 1 !important;
}

.container-serve .swiper-pagination-bullet {
  background-color: #fff !important;
  border: 5px solid #fff !important;
  opacity: 1 !important;
}

.container-tace .swiper-pagination-bullet {
  background-color: #fff !important;
  border: 5px solid #fff !important;
  opacity: 1 !important;
}

.container-1 .swiper-pagination-bullet-active {
  background-color: #E88A0D !important;
  border: 5px solid #E88A0D !important;
}

.container-serve .swiper-pagination-bullet-active {
  background-color: #1F3B71 !important;
  border: 5px solid #1F3B71 !important;
}

.container-tace .swiper-pagination-bullet-active {
  background-color: #1F3B71 !important;
  border: 5px solid #1F3B71 !important;
}

.container-serve .swiper-custom-pagination {
  margin-top: 3%;
  text-align: center;
}

.container-serve .darkivore-page {
  margin-top: 10%;
  text-align: center;
}

.container-tace .tacivoar-page {
  margin-top: 7%;
  text-align: center;
  margin-bottom: 7%;
}

.container-tace .tacivoar-feature-page {
  margin-top: 7%;
  text-align: center;
  margin-bottom: 7%;
}

.container-1 .swiper-custom-pagination {
  margin-top: 8vh;
  text-align: center;
}

.swiper-mobile {
  margin-top: 10%;
  text-align: center;
}

.swiper-client {
  margin-top: 7%;
  text-align: center;
}

.swiper-serve {
  margin-top: 7%;
  text-align: center;
}

@media screen and (max-width: 800px) {

  .productSwiper,
  .swiper-custom-pagination {
    display: none;
  }

  .container-1 .swiper-pagination-bullet {
    background-color: #fff !important;
    border: 2px solid #fff !important;
    opacity: 1 !important;
  }
  
  .container-serve .swiper-pagination-bullet {
    background-color: #fff !important;
    border: 2px solid #fff !important;
    opacity: 1 !important;
  }
  
  .container-tace .swiper-pagination-bullet {
    background-color: #fff !important;
    border: 2px solid #fff !important;
    opacity: 1 !important;
  }
  
  .container-1 .swiper-pagination-bullet-active {
    background-color: #E88A0D !important;
    border: 2px solid #E88A0D !important;
  }
  
  .container-serve .swiper-pagination-bullet-active {
    background-color: #1F3B71 !important;
    border: 2px solid #1F3B71 !important;
  }
  
  .container-tace .swiper-pagination-bullet-active {
    background-color: #1F3B71 !important;
    border: 2px solid #1F3B71 !important;
  }
}

@media screen and (min-width: 801px) {
  .statSwiper {
    width: 100%;
  }
}